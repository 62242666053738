exports.components = {
  "component---src-pages-about-us-expertise-index-tsx": () => import("./../../../src/pages/about-us/expertise/index.tsx" /* webpackChunkName: "component---src-pages-about-us-expertise-index-tsx" */),
  "component---src-pages-about-us-join-us-index-tsx": () => import("./../../../src/pages/about-us/join-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-join-us-index-tsx" */),
  "component---src-pages-about-us-sethness-roquette-index-tsx": () => import("./../../../src/pages/about-us/sethness-roquette/index.tsx" /* webpackChunkName: "component---src-pages-about-us-sethness-roquette-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-news-add-tsx": () => import("./../../../src/pages/admin/news/add.tsx" /* webpackChunkName: "component---src-pages-admin-news-add-tsx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-01-13-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-01-13.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-01-13-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-02-08-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-02-08.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-02-08-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-03-15-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-03-15.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-03-15-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-18-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-04-18.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-18-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-29-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-04-29.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-29-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-07-29-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-07-29.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-07-29-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2023-11-02-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2023-11-02.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2023-11-02-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-07-19-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2024-07-19.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-07-19-mdx" */),
  "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-08-26-mdx": () => import("./../../../src/pages/admin/news/edit/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2024-08-26.mdx" /* webpackChunkName: "component---src-pages-admin-news-edit-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-08-26-mdx" */),
  "component---src-pages-admin-news-index-tsx": () => import("./../../../src/pages/admin/news/index.tsx" /* webpackChunkName: "component---src-pages-admin-news-index-tsx" */),
  "component---src-pages-application-baking-index-tsx": () => import("./../../../src/pages/application/baking/index.tsx" /* webpackChunkName: "component---src-pages-application-baking-index-tsx" */),
  "component---src-pages-application-brewery-index-tsx": () => import("./../../../src/pages/application/brewery/index.tsx" /* webpackChunkName: "component---src-pages-application-brewery-index-tsx" */),
  "component---src-pages-application-confectionery-index-tsx": () => import("./../../../src/pages/application/confectionery/index.tsx" /* webpackChunkName: "component---src-pages-application-confectionery-index-tsx" */),
  "component---src-pages-application-dairy-index-tsx": () => import("./../../../src/pages/application/dairy/index.tsx" /* webpackChunkName: "component---src-pages-application-dairy-index-tsx" */),
  "component---src-pages-application-flavors-index-tsx": () => import("./../../../src/pages/application/flavors/index.tsx" /* webpackChunkName: "component---src-pages-application-flavors-index-tsx" */),
  "component---src-pages-application-pet-food-index-tsx": () => import("./../../../src/pages/application/pet-food/index.tsx" /* webpackChunkName: "component---src-pages-application-pet-food-index-tsx" */),
  "component---src-pages-application-savoury-index-tsx": () => import("./../../../src/pages/application/savoury/index.tsx" /* webpackChunkName: "component---src-pages-application-savoury-index-tsx" */),
  "component---src-pages-application-soft-drink-index-tsx": () => import("./../../../src/pages/application/soft-drink/index.tsx" /* webpackChunkName: "component---src-pages-application-soft-drink-index-tsx" */),
  "component---src-pages-application-spirits-index-tsx": () => import("./../../../src/pages/application/spirits/index.tsx" /* webpackChunkName: "component---src-pages-application-spirits-index-tsx" */),
  "component---src-pages-caramel-caramel-color-index-tsx": () => import("./../../../src/pages/caramel/caramel-color/index.tsx" /* webpackChunkName: "component---src-pages-caramel-caramel-color-index-tsx" */),
  "component---src-pages-caramel-caramel-ingredients-index-tsx": () => import("./../../../src/pages/caramel/caramel-ingredients/index.tsx" /* webpackChunkName: "component---src-pages-caramel-caramel-ingredients-index-tsx" */),
  "component---src-pages-caramel-caramelized-sugar-syrups-burnt-sugar-index-tsx": () => import("./../../../src/pages/caramel/caramelized-sugar-syrups-burnt-sugar/index.tsx" /* webpackChunkName: "component---src-pages-caramel-caramelized-sugar-syrups-burnt-sugar-index-tsx" */),
  "component---src-pages-caramel-specialty-caramels-index-tsx": () => import("./../../../src/pages/caramel/specialty-caramels/index.tsx" /* webpackChunkName: "component---src-pages-caramel-specialty-caramels-index-tsx" */),
  "component---src-pages-documentation-product-documentation-index-tsx": () => import("./../../../src/pages/documentation/product-documentation/index.tsx" /* webpackChunkName: "component---src-pages-documentation-product-documentation-index-tsx" */),
  "component---src-pages-documentation-statements-certificates-index-tsx": () => import("./../../../src/pages/documentation/statements-certificates/index.tsx" /* webpackChunkName: "component---src-pages-documentation-statements-certificates-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-cookies-tsx": () => import("./../../../src/pages/legal/cookies.tsx" /* webpackChunkName: "component---src-pages-legal-cookies-tsx" */),
  "component---src-pages-legal-data-protection-tsx": () => import("./../../../src/pages/legal/data-protection.tsx" /* webpackChunkName: "component---src-pages-legal-data-protection-tsx" */),
  "component---src-pages-legal-ethics-compliance-tsx": () => import("./../../../src/pages/legal/ethics-compliance.tsx" /* webpackChunkName: "component---src-pages-legal-ethics-compliance-tsx" */),
  "component---src-pages-legal-legal-notice-tsx": () => import("./../../../src/pages/legal/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-legal-notice-tsx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-01-13-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-01-13.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-01-13-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-02-08-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-02-08.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-02-08-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-03-15-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-03-15.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-03-15-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-18-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-04-18.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-18-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-29-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-04-29.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-04-29-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-07-29-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2022-07-29.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2022-07-29-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2023-11-02-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2023-11-02.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2023-11-02-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-07-19-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2024-07-19.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-07-19-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-08-26-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/codebuild/output/src4047101651/src/sethness-roquette-website/src/assets/news/2024-08-26.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-codebuild-output-src-4047101651-src-sethness-roquette-website-src-assets-news-2024-08-26-mdx" */)
}

